<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :afterIcon="
              [
                {
                  name: 'search',
                  click: true,
                  callbackName: 'searchWorkPermit',
                },
                {
                  name: 'close',
                  click: true,
                  callbackName: 'removeWorkPermit',
                },
              ]
            "
            :editable="editable"
            :readonly="true"
            label="작업허가번호"
            name="permitNo"
            v-model="searchParam.permitNo"
            @searchWorkPermit="searchWorkPermit"
            @removeWorkPermit="removeWorkPermit"
          >
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            :editable="editable"
            label="LBLPROCESS"
            name="processCd"
            v-model="searchParam.processCd"
          >
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :afterIcon="
              [
                {
                  name: 'search',
                  click: true,
                  callbackName: 'searchWorkName',
                },
                {
                  name: 'close',
                  click: true,
                  callbackName: 'removeWorkName',
                },
              ]
            "
            :editable="editable"
            :readonly="true"
            :close="false"
            label="LBLJOB"
            name="sopName"
            v-model="searchParam.sopName"
            @searchWorkName="searchWorkName"
            @removeWorkName="removeWorkName"
          >
          </c-text>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-project-location
            :editable="editable"
            :disabled="isWorkPermit || disabled"
            :plantCd="searchParam
            .plantCd"
            label="작업장소"
            name="sopMapId"
            v-model="searchParam.sopMapId"
          />
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-vendor
            :editable="editable"
            label="업체"
            name="vendorCd"
            v-model="searchParam.vendorCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="작업기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 작성상태 -->
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="tbmCompleteFlag"
            label="작성상태"
            v-model="searchParam.tbmCompleteFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
      <c-table
        ref="table"
        title="TBM 목록"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :columnSetting="false"
        :isFullScreen="false"
        :usePaging="false"
        :expandAll="true"
        @linkClick="linkClick"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name ==='noUpload'">
            <q-chip
              :color="props.row.noUpload === 'N' ? 'blue' : 'red'"
              :class="props.row.noUpload === 'N' ? null : 'blinking'"
              outline square
              text-color="white">
              {{props.row.noUpload === 'N' ? '업로드' : '미업로드'}}
            </q-chip>
          </template>
        </template>
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="linkClick" />
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
      </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'tbm',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            style: 'width:110px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmNo',
            field: 'tbmNo',
            label: 'TBM번호',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmName',
            field: 'tbmName',
            label: 'TBM명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'permitNo',
            field: 'permitNo',
            label: '작업허가번호',
            style: 'width:150px',
            align: 'center',
            type: 'link',
            sortable: true,
          },
          {
            name: 'tbmWorkDate',
            field: 'tbmWorkDate',
            label: '작업일',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmCompleteFlagName',
            field: 'tbmCompleteFlagName',
            label: '작성상태',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            // 결재진행단계
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: 'LBL0000212',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'processName',
            field: 'processName',
            style: 'width:80px',
            label: 'LBLPROCESS',
            align: 'center',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            style: 'width:80px',
            label: '업체',
            align: 'center',
            sortable: true,
          },
          {
            name: 'sopName',
            field: 'sopName',
            style: 'width:80px',
            label: 'LBLJOB',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmWorkResponsibleName',
            field: 'tbmWorkResponsibleName',
            label: '작업책임자',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'workUserCount',
          //   field: 'workUserCount',
          //   label: '외',
          //   style: 'width:40px',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'permitTypeName',
            field: 'permitTypeName',
            label: '허가서구분',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workTime',
            field: 'workTime',
            label: '작업시간',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'supplementWorkTypeNames',
            field: 'supplementWorkTypeNames',
            style: 'width:80px',
            label: '보충작업',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      period: ['', ''],
      searchParam: {
        startYmd: '',
        endYmd: '',
        plantCd: '',
        processCd: '',
        vendorCd: '',
        mdmSopId: '',
        sopName: '',
        permitNo: '',
        sopWorkPermitId: '',
        sopMapId: '',
        mapName: '',
        tbmCompleteFlag: null,

      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      statusItems: [
        { code: 'N', codeName: '작성중' },
        { code: 'Y', codeName: '작성완료' },
      ],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        // isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.tbm.list.url;
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col) {
      this.detailPopup(row, col);
    },
    detailPopup(result, col) {
      if (col && col.name === 'permitNo') {
        this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitDetail.vue"}`);
        this.popupOptions.title = '작업허가서 상세';
        this.popupOptions.param = {
          sopWorkPermitId: result ? result.sopWorkPermitId : '',
          permitTypeCd: result ? result.permitTypeCd : '',
        };
      } else {
        this.popupOptions.target = () =>
        import(`${"./tbmDetail.vue"}`);
        this.popupOptions.title = 'TBM 상세';
        this.popupOptions.param = {
          tbmId: result ? result.tbmId : '',
        };
      }
      this.popupOptions.isFull = true;
      // this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    searchWorkPermit() {
      this.popupOptions.title = "작업허가서 선택"; // 작업허가서 선택
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.searchParam.sopWorkPermitId = data[0].sopWorkPermitId;
        this.searchParam.permitNo = data[0].permitNo;
      }
    },
    removeWorkPermit() {
      this.searchParam.sopWorkPermitId = '';
      this.searchParam.permitNo = '';
    },
    // 작업 팝업 선택
    searchWorkName() {
      this.popupOptions.title = "작업 선택"; // 작업 선택
      this.popupOptions.param = {
        type: "single",
        searchType: "1", //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
        plantCd: this.searchParam.plantCd,
        subProcessCd: this.searchParam.processCd
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/common/process/sopPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeWorkNamePopup;
    },
    closeWorkNamePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.searchParam.mdmSopId = data[0].mdmSopId;
        this.searchParam.sopName = data[0].sopName;
        this.isEdit = false;
      }
    },
    removeWorkName() {
      this.searchParam.mdmSopId = '';
      this.searchParam.sopName = '';
    },
  }
};
</script>
